import React from 'react';
import { Link,navigate } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import Wave from "@components/wave/wave";
import ImageRenderer from "@components/ImageRenderer/ImageRenderer";
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { ArrowLeft, Locations, OfficePhone, OfficeEmail } from '@components/icon/icon';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

const generalEnq= (officedata) => {
    typeof window !== "undefined" && window.localStorage.setItem('office_details', (JSON.stringify({ officeinfo: officedata})))
    navigate('/contact/general-enquiry/?contact=officecontact')
}

const PropertyTop = (props) => {

    const handleBack = () => window.history.back();


    const Banner_Image = props?.data?.Banner_Image?.internal.description ? props?.data?.Banner_Image?.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

    //console.log("offices", props)

    return (
        <div className="property-top">
            <Container>
                <Row className="justify-content-between property-row">
                    <Col lg="6">
                        <h1 className='h2'>
                            {props.data.Title}
                        </h1>
                        <p className='office-intro-text'>{props.data.Address}</p>
                        <div className='office-btn-list'>
                            <Link to={`/contact/general-enquiry/?office=${props.data.URL}`} className="btn">Book an appointment</Link>
                            <a  className="btn alt" href="/value-my-property/property-valuation/">Book a valuation</a>                            
                         </div>
                         <ul className='contact-list'>
                            <li><Locations />{props.data.Address}</li>
                            <li><a href={`tel:${props.data.Phone_Number}`}><OfficePhone />{props.data.Phone_Number}</a></li>
                            <li className='officeEmail'><a href={`mailto:${props.data.Email}`}><OfficeEmail />{props.data.Email}</a></li>                           
                         </ul>
                         <div class="timing">
                            <div class="title text-20 bold">Opening Hours</div>
                                <div class="list">
                                    <td class="timing-title text-20"><span>Mon – Thur:</span><span>9am to 6:30 pm</span></td>
                                    <td class="timing-title text-20"><span>Fri:</span><span>9am to 6pm</span></td>
                                    <td class="timing-title text-20"><span>Sat:</span><span>9:30 am to 4pm</span></td>
                                </div>
                            </div>
                    </Col>
                    <Col lg="6">
                    <ImageRenderer
                  ImageSrc={{url:Banner_Image}} altText={props?.data?.Title} ggfx_results={props?.data?.ggfx_results} strapi_id={props?.data?.strapiId} imagename="offices.Tile_Image.detail-Img"
                />
                       {/*<Wave Title={props.data.Title+' Banner'} VideoBG={props.data.Banner_Image} VideoURL={props.data.Embed_Video_URL} />*/}
                    </Col>
                </Row>
                {/*<Row  className="justify-content-between property-row office-top">
                    <Col lg="8">
                        <Row>
                            <Col lg="3" md="6">
                                <h6>Phone Number:</h6>
                                <h5><a href={`tel:${props.data.Phone_Number}`}>{props.data.Phone_Number}</a></h5>
                            </Col>
                            <Col lg="7" md="6">
                                <h6>Email:</h6>
                                <h5><a href={`mailto:${props.data.Email}`}>{props.data.Email}</a></h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" className="d-none d-lg-block">
                        <h6>Looking to Sell or Let?</h6>
                        <h6><span><a href="/value-my-property/property-valuation/">Book a Property Valuation</a></span></h6>
                    </Col>
                </Row>*/}
            </Container>
        </div>
    )
}

export default PropertyTop
