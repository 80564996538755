import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import { Link } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap";
import Slider from 'react-slick';
import gql from "graphql-tag";
import StaticUrls from "../common-data/static-urls";
import PropertyCard from './PropertyCard';
import $ from "jquery";
import "./OntheMarket.scss";

const FEATURED_PROPERTY = gql`
  query GetProperty ($ptype: String!){
      properties(
        where: {publish: true, search_type: $ptype}
        sort: "available_from:DESC"
        limit: 5
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;


const SOLD = gql`
  query GetProperty ($ptype: String!){
      properties(
        where: {status_in: ["Sold", "sold"], publish: true, search_type: $ptype}
        sort: "available_from:DESC"
        limit: 5
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;

const AreaSimilarProperties = (props) => {
  const { loading, error, data } = useQuery(FEATURED_PROPERTY, { variables: { ptype: "sales" } });
  const { loading: letLoad, error: letErr, data: lettingsList } = useQuery(FEATURED_PROPERTY, { variables: { ptype: "lettings" } });
  const { loading: soldLoad, error: newErr, data: soldlist } = useQuery(SOLD, { variables: { ptype: "sales" } });
  //const { loading: interLoad, error: interErr, data: internationalList } = useQuery(INTERNATIONAL_PROPERTIES);
  const [activetype, setActivceType] = useState("sold") 
 
  useEffect(() => {
    /*var propertySelector = '.all-featured-properties .prop-tab-list li';

    $(propertySelector).on('click', function(){
        $(propertySelector).removeClass('active');
        $(this).addClass('active');
    });*/
    
  },[]);

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
          dots: false,
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) return null;

  return (
      <section className={`onthemarket-module AreaSimilarProperties`}>
          <Container>
          <div className="module-content">
               <div className="heading-section ">
                  <h3 className="spacing-bottom-24">{props.title}  Properties</h3>        
                  <div className="prop-tab-list">                  
                      <button className={activetype == "sold" ? "active" : ""}onClick={() => { setActivceType('sold') }}>Recently Sold</button>
                      <button className={activetype == "sales" ? "active" : ""} onClick={() => { setActivceType('sales') }}>For Sale</button>
                      <button className={activetype == "lettings" ? "active" : ""}onClick={() => { setActivceType('lettings') }}>To Rent</button>
                  </div>
                </div>          
            <div className="module-slider">        
                <div className={`slick-wrap ${activetype == "sales" ? 'd-block' : 'd-none'}`}>
                  {data?.properties.length ?
                    <Slider {...settings}>

                      {data && data.properties.map((item, index) => {
                        return (
                          <PropertyCard id={item?.id} title={item?.display_address} price={item?.price} image={item?.images?.[0]} ggfx_results={item?.ggfx_results} searchType={item?.search_type} slug={item?.slug} bedroom={item?.title} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                <div className={`slick-wrap ${activetype == "lettings" ? 'd-block' : 'd-none'}`}>
                  {lettingsList?.properties.length ?
                    <Slider {...settings}>

                      {lettingsList && lettingsList.properties.map((item, index) => {
                        return (
                        <PropertyCard id={item?.id} title={item?.display_address} price={item?.price} image={item?.images?.[0]} ggfx_results={item?.ggfx_results} searchType={item?.search_type} slug={item?.slug} bedroom={item?.title} />
                        
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                <div className={`slick-wrap ${activetype == "sold" ? 'd-block' : 'd-none'}`}>
                  {soldlist?.properties.length ?
                    <Slider {...settings}>

                      {soldlist && soldlist.properties.map((item, index) => {
                        return (
                          <PropertyCard id={item?.id} title={item?.display_address} price={item?.price} image={item?.images?.[0]} ggfx_results={item?.ggfx_results} searchType={item?.search_type} slug={item?.slug} bedroom={item?.title} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>               
                </div>   
                </div>        
          </Container>
        </section>
  );
};

export default AreaSimilarProperties;