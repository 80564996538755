import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useStaticQuery, graphql } from "gatsby";

/**
 * Assets
 */

import './styles/_index.scss';

const Breadcrumbs = (props) => {

if (typeof window !== "undefined") {
    var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
    if(props.alias !=null) {
      var lastURLSegment = props.alias
    }
}

 const data = useStaticQuery(graphql`
    query BreadcrumbQuery {  
      site {
        siteMetadata {
          title
        }
      }
      
    allStrapiAllMenus {
      edges {
        node {
          id
          Label
          Show_In_Burger_Menu
          URL
          main_parent {
            URL
            Show_In_Burger_Menu
            Label
          }
          sub_parent {
            URL
            Label
          }
        }
      }
    }    
    }
  `)

    return (

    <div className="breadcrumbs">
      <Container>
			<ul>
				<li><Link to="/">Home</Link></li>
				{data.allStrapiAllMenus.edges.map(({node}, key) => {
				return <>
				{node.main_parent != null && node.URL == lastURLSegment &&
				<li><Link to={`/${node.main_parent.URL}/`}>{node.main_parent.Label}</Link></li>
				}
				{node.main_parent != null && node.sub_parent != null && node.URL == lastURLSegment &&
				<li><Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/`}>{node.sub_parent.Label}</Link></li>
				}
				{node.main_parent != null && node.URL == lastURLSegment && props.alias == null &&
				<li><Link>{node.Label}</Link></li>
				}
        {node.main_parent != null && node.URL == lastURLSegment && props.detailTitle != null && props.alias !=null &&
        <li><Link to={`/${node.main_parent.URL}/${node.URL}/`}>{node.Label}</Link></li>
        }
        {node.main_parent != null && node.URL == lastURLSegment && props.alias !=null && props.detailTitle == null &&
        <li><Link to={`/${node.main_parent.URL}/${node.URL}/`}>{node.Label}</Link></li>
        }
        {node.main_parent == null && node.sub_parent == null && node.URL == lastURLSegment &&
        <li><Link>{node.Label}</Link></li>
        }       
				</>
				})}
				{props.detailTitle !=null && props.alias !=null &&
          <li><Link>{props.detailTitle}</Link></li>
        }
			</ul>
      </Container>
        </div>
    )
}

export default Breadcrumbs;
