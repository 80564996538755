import React from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "aos/dist/aos.css";
import get from 'lodash/get'
import OfficeTop from "@components/office-top/office-top";
import FeaturedProperty from "@components/featured-property/featured-property";
import HomeContact from "@components/home-contact/home-contact";
import Valuation from "@components/valuation/valuation";
import HomeTextColumns from "@components/home-text-columns/home-text-columns";
import OfficeTeamList from "@components/office-team-slider/office-team-list";
import HomeRowImgText from "@components/home-row-img-text/home-row-img-text";
import Officemap from "@components/office-location/office-location";
import FullWidthTwoColModule from "@components/modules/FullWidthTwoColModule/FullWidthTwoColModule";
import FormModule from "@components/modules/FormModule/FormModule";
import FAQModule from "@components/modules/FAQModule/FAQModule";
import ReviewsModule from "@components/Reviews/ReviewsModule";
import AreaSimilarProperties from "@components/PropertyCarousel/AreaSimilarProperties";
import axios from 'axios';
import { graphql } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "gatsby";
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';


class OfficeTemplate extends React.Component {

  render() {
    //Detail pages match
    //Default fetch


   // const ReviewsData = get(this, 'props.data?.strapiGlobalConfig?.Elfsight_Reviews')
  // const FormData = props.data?.strapiGlobalConfig?.GetStartedForm;

    const Page = get(this, 'props.data.strapiOffices')
    const Office = get(this, 'props.data.strapiOffices')
    const GlobalConfig = get(this, 'props.data.strapiGlobalConfig')
    const Modules = get(this, 'props.data.strapiOffices.Modules')
    const TeamMember = get(this, 'props.data.strapiOffices.Team_Members')
    const TeamSlider = get(this, 'props.data.strapiOffices.Slider_Team_Members')

//console.log("Office", Office)
    //console.log("GlobalConfig", TeamSlider)

    return (
      <>
        <SEO title={Office.Meta_Title} description={Office.Meta_Description} />
        <Layout guides={Page.Select_Areaguide} area={Page.URL} popularSearch="staticdetails" footerDetails={GlobalConfig}>
          <div className="breadcrumbs-intro office-breadcrumbs-intro">
               <Breadcrumbs detailTitle={Office.Title} alias="our-offices"/>
          </div>
          <div className="property-wrapper office-details office-details-template">
            <OfficeTop data={Office} />
            {/*Office.Banner_Image != null &&
              <Wave Title={Office.Title+' Banner'} VideoBG={Office.Banner_Image} VideoURL={Office.Embed_Video_URL} />
            */}
            {Office.Intro_Left_Content != null &&
              <HomeTextColumns ContentLeft={Office.Intro_Left_Content} ContentRight={Office.Intro_Right_Content} />
           }
           
            {Modules.map((Modules, i) => {
              
              return (
                <>
                {Modules.Full_Width_Two_Col_CTA_Label &&
                <FullWidthTwoColModule ModuleData={Modules} ggfx_results={Office.ggfx_results} StrapiId={Office?.strapiId}  />
                }
                {Modules.ModuleType == "FormModule" &&
                    <FormModule ModuleData={Modules} />
                }
                {Modules.Select_Module === "Reviews" &&
                     <ReviewsModule reviewsData={GlobalConfig?.Elfsight_Reviews}/>
                }
                {Modules.Select_Module == "Team_Slider" &&
                    <OfficeTeamList officearea={Office.Area} data={TeamSlider} />
                }                
                {Modules.ModuleType == "FAQModule" &&
                    <FAQModule ModuleData={Modules} />
                }
                {Modules?.Select_Module === "On_the_market" &&
                    <AreaSimilarProperties title={Modules?.Select_Module_Title} />
                }                
                {/*Modules.Select_Module == "Get_In_Touch" &&
                    <HomeContact data={TeamMember} Content={GlobalConfig.Get_In_Touch_Content} />
                */}
                {/*Modules.Select_Module=='Office_detail_Map' &&
                    <Officemap data={Office} Latitude={Office.Latitude} Longitude={Office.Longitude} mapimage={Office.Map_Block_Image} mapvideo={Office.Map_Block_Video_Url} Areaguide={Office.Select_Areaguide} />
                */}
                </>
              )
            })}
            {/*<HomeRowImgText reviewkey= {Office.Review_Key}/>*/}
            
            {/*<FeaturedProperty officeId={Office.CRM_Office_ID} area={Office.Area} showsale="true" showrent="true" Title={`Featured Properties in ${Office.Title}, London`} />*/}
           
          </div>
      <div className="property-fixed-bar footer-cta d-lg-none">
        <Container>
            <Row className="justify-content-between">
                <Col md="12" lg="auto">
                    <div className="fixed-utils">
                        <a href={`tel:${Office.Phone_Number}`} className="btn dark d-lg-none">Call Us</a>
                        <Link href="/value-my-property/property-valuation/" className="btn">Valuation</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
        </Layout>
      </>
    )
  }
}

export default OfficeTemplate

export const pageQuery = graphql`
query OfficeQuery($slug: String!) {
  strapiOffices(URL: {eq: $slug}) {
    strapiId
    Meta_Title
    Meta_Description
    Title   
    Review_Key 
    Area
    Address
    Phone_Number
    Email
    Latitude
    Longitude
    Intro_Left_Content
    Intro_Right_Content
    CRM_Office_ID
    URL
    Map_Block_Image {
      publicURL
    }
    ggfx_results {
      id     
      transforms {
         url
      }     
      src_cftle     
    }
    Select_Areaguide {
      URL
      Title
      Embed_Video_URL
      Tile_Image {
        internal {
          description
        }
      }
    }
    Map_Block_Video_Url
    Tile_Image {
      internal {
        description
      }
    }
    Banner_Image {
      internal {
        description
      }
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagetransforms {
      Tile_Image_Transforms
    }
    Embed_Video_URL
    Team_Members {
          id
          Designation
          Email
          Calendly_Link
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    }
    Slider_Team_Members {
         ggfx_results
          id
          Email
          URL
          Designation
          Name
          Embed_Video_URL
          imagetransforms {
            Image_Transforms
          }
          Image {
            internal {
              description
            }
          }
          Phone
    }
    Modules {
    ModuleType
     ModuleSpacing
     Heading
      CTA_Label
      FAQ_Items {
            Answer
            Question
            id
      }
      CTA_Link{
      id
      URL
      Link_Type
        Label
      }
      Select_Module_Title
      Section_Title
      Section_Intro
      FormList      
      Show_Reviews      
      Select_Module     
      Full_Width_Two_Col_Title
       Full_Width_Two_Col_Content
      Full_Width_Two_Col_CTA_Label
      Full_Width_Two_Col_CTA_URL
      Full_Width_Two_Col_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }
  }
  strapiGlobalConfig {
       Get_In_Touch_Content
       Elfsight_Reviews {
        Badge_Widget_Code
        Carousel_Widget_Code
        Widget_Embed_Video_URL
        Widget_Video_Bg_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
      GetStartedForm {
       Section_Title
        Section_Intro
        FormList
        Show_Reviews
        FormContactData {
          Label
          Link
          Type
          Icon_Type
          Show_Icon
        }
      }
  }
}
`
