import React from "react"
import { getImage } from "gatsby-image"
import GGFXTransform from "../../components/common/stb-jamstack-ggfxclient/module/components/ggfx-transform";
// mponents/common/stb-jamstack-ggfxclient/module/components/ggfx-transform
import NoImage from "../../images/no-image.png";

const ImageRenderer = (props) => {
        if(props.ImageSrc && props.ImageSrc.url){

        var image_url = props.ImageSrc.url;
        if(props.ImageSrc.url_sharp){
            image_url = getImage(props.ImageSrc.url_sharp)
        }

        let alt_text = (props.ImageSrc.alternativeText ? props.ImageSrc.alternativeText : props?.altText)+" - "+process.env.GATSBY_SITE_NAME;
        let renderer = props.renderer ? props.renderer :"srcSet";

        // console.log("props ==>", props)
        return(
            <>
                {props.imagename ? ( 
                      <GGFXTransform mediaUrl={props.ImageSrc.url} renderer={renderer} transformConfigName={props.imagename} preResult={props?.ggfx_results} id={props.strapi_id} attr={{...props.attr, alt:alt_text}}/>
                    ) : (
                      <img className={props.attr.classNames} src={image_url} alt={alt_text} title={props.title} loading="lazy" />
                    )
                }
                
            </>
        )
    } else{
        return <img src={NoImage} alt="img" />;
    }
}
export default ImageRenderer;